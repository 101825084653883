
:root {
    --main-img-height: 100%;
}


.main__rubric-preview {
    width: 100%;
    margin: 0 auto;
}

.main__rubric-preview img {
    width: 100%;
    max-height: 720px;
    -o-object-fit: cover;
       object-fit: cover;
}

.main__rubric-description .rubric__content {
    margin-left: 65px;
    margin-bottom: 15px;
    text-align: left;
    max-width: 40%;
    padding-left: 45px;
    padding-right: 45px;
}

@media screen and (max-width: 1280px) {
    .main__rubric-description .rubric__content {
        max-width: 50%;
        margin-left: 53px;
    }
}


@media screen and (max-width: 992px) {
    .main__rubric-description .rubric__content {
        max-width: 60%;
        margin-left: 21px;
    }
}


@media screen and (max-width: 767px) {
    .main__rubric-description .rubric__content {
        margin-left: 0;
        margin-bottom: 0;
        padding-left: 14px;
        padding-right: 0;
        max-width: none;
    }
}

.main__rubric-title {
    font-family: 'FuturaPT-Demi';
    font-size: 16px;
    letter-spacing: 1px;
    color: #000;
    text-transform: uppercase;
    padding-bottom: 13px;
}

.main__rubric-description .rubric__title {
    text-transform: uppercase;
}

.main__rubric-description .rubric__description {
    padding-left: 0;
}

.main__rubric-photo {
    padding: 0;
    height: var(--main-img-height);
}

.barometr__read-also, .foam__read-also {
    margin-bottom: 45px;
}

.main__page .association-inner__slider {
    background-color: #32a5b3;
}

.main__page .collections {
    margin: 45px 0 90px;
}

.foam__item {
    text-align: left !important;
}

.foodpairing__content span {
    color: #8a9a23;
}

.main__page .button__loadmore {
    max-width: 300px;
    padding: 15px 20px;
}

.main__page .news {
    display: block !important;
}