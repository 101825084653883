.expert-inner{
    display:none;
    width: 650px;
    height: auto;
    background-color: #fff;
    position: fixed;
    left: 33%;
    top: 10%;
    -webkit-transition: .5s;
    transition: .5s;
    z-index: 100;
    overflow-y: auto;
    padding-bottom: 50px;

}

.expert-inner__close {
    width: 25px;
    height: 25px;
    margin: 50px auto 0;
    background: url('../img/icons/cross.svg') no-repeat;
    cursor: pointer;
    z-index: 101;
}

.expert-title{
    text-align: center;
    font-size: 32px;
}

.expert-position-image{
    position: relative;
    text-align: center;
    padding-top: 55px;
}

.expert-position{
  font-family: FuturaPT-Demi;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 4.5;
  letter-spacing: 0.8px;
  text-align: center;
  color: #000000;
  color: var(--black);
  margin-bottom: -20px;
  text-transform: uppercase;
}

.expert-history{
  font-family: PlayfairDisplay;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.75;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
  color: var(--black);
  padding-right: 100px;
  padding-left: 100px;
}

.expert-item{
    margin: 0px 10px 10px 0;
    width: calc(20% - 10px);
}


@media screen and (max-width: 1024px) {
    .expert-inner{
        left: 21%;
        width: 575px;
    }

    .expert-item{
        width:calc(33.33% - 10px)
    }
}

@media screen and (max-width: 768px) {
    .expert-inner{
        left: 13%;
        width: 575px;
    }

    .expert-item{
        width: calc(49% - 5px);
    }
}

@media screen and (max-width: 425px) {
    .expert-inner{
        left: 0%;
        top: 0%;
        width: 100%;
        height: 100%;
    }

    .expert-position{
        font-size: 12px;
    }

    .expert-history{
        padding-right: 50px;
        padding-left: 50px;
        font-size: 14px;
        text-align: center;
    }

    .expert-title{
        font-size: 28px;
        padding-top: 5px;
        padding-bottom: 10px;
    }

    .expert-item{
        width: calc(49% - 7px);
    }
    .expert-item img{
        margin: 0 auto 5px;
        height: 100px;
        width: 100px;
    }
}

