.tabs {
    width: 100%;
    margin-bottom: 20px;
}

@media screen and (max-width: 767px) {
    .tabs {
        width: auto;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        margin: 10px 5px 20px;
    }
}

.tabs a {
    font-family: 'FuturaPT-Book';
    letter-spacing: 1px;
    text-transform: uppercase;
    padding: 6px 15px;
    -webkit-transition: .15s;
    transition: .15s;
    display: inline-block;
    background-color: #fff;
    margin-right: 10px;
    margin-bottom: 10px;
}


@media screen and (max-width: 767px) {
    .tabs a {
        display: none;
    }

    .tabs a:first-of-type {
        display: inline-block;
        margin: 0;
        font-size: 12px;
        padding: 8.5px 15px;
    }
}

.tabs a:hover {
    background-color: #000;
    color: #fff;
}

.tabs-active {
    background-color: #000;
    color: #fff;
}

.tabs__mobile-title {
    display: none;
    padding: 8.5px 20px 8.5px 10px;
    text-align: center;
    background: url('../img/icons/arrow2.svg') no-repeat;
    background-size: 5px;
    background-position-y: center;
    background-position-x: 95%;
    cursor: pointer;
    max-width: 100px;
    background-color: #fff;
    font-family: 'FuturaPT-Book';
    font-size: 12px;
    text-transform: uppercase;
}

@media screen and (max-width: 767px) {
    .tabs__mobile-title {
        display: block;
    }
}


.tabs__mobile {
    position: fixed;
    background-color: #fff;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
    display: none;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    z-index: 10;
}

.tabs__mobile a {
    width: 100%;
    font-family: 'FuturaPT-Book';
    font-size: 14px;
    text-transform: uppercase;
    text-align: center;
}

.tabs__close {
    width: 100%;
    height: 30px;
    background: url('../img/icons/cross.svg') center no-repeat;
    background-size: 30px;
    cursor: pointer;
}