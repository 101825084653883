.modal__age {
    padding: 35px 70px;
    background-color: #fff;
    position: fixed;
    top: calc(50% - 180px);
    display: none;
    z-index: 100;
    max-width: 650px;
    margin: 0 auto;
    left: 0;
    right: 0;
}

@media screen and (max-width: 992px) {
    .modal__age {
        max-width: 575px;
    }
}

@media screen and (max-width: 767px) {
    .modal__age {
        max-width: none;
        height: 100%;
        top: 0;
        padding: 31% 43px;
    }
}

.modal__age-title {
    font-size: 64px;
    letter-spacing: 2.9px;
    color: #d0021b;
    font-family: 'FuturaPT-Bold';
    text-align: center;
}

.modal__age-description {
    font-family: 'FuturaPT-Book';
    font-size: 18px;
    line-height: 1.67;
    text-align: center;
    padding: 15px 0 25px;
}

@media screen and (max-width: 992px) {
    .modal__age-description {
        font-size: 16px;
    }
}

.modal__age-buttons {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}

@media screen and (max-width: 767px) {
    .modal__age-buttons {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
    }
}

.modal__age-button {
    max-width: 220px;
    width: 100%;
    padding: 15px 20px;
}

.modal__age-button a {
    color: #000;
}

.modal__age-button a:hover {
    color: #fff;
}

@media screen and (max-width: 992px) {
    .modal__age-button {
        max-width: 200px;
        font-size: 16px;
    }
}

@media screen and (max-width: 767px) {
    .modal__age-button {
        max-width: 200px;
        font-size: 12px;
        margin-bottom: 25px;
    }
}