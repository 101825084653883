.news__page-content {
    margin-top: auto;
}

.news__page-content img {
    max-width: 100%;
    margin: 0 auto;
    display: block;
    margin-bottom: 20px;
    left: auto;
    right: auto;
}