
.bar-inner__page .bottom {
    padding-top: unset;
}

.bar-inner__page .top {
    padding-bottom: unset;
}

@media screen and (max-width: 767px) {
    .bar-inner__page .top {
        padding-left: 0px;
        padding-right: 0px;
    }
}

.bar-inner__page-title {
    font-family: 'FuturaPT-ExtraBold';
    font-size: 48px;
    letter-spacing: 1px;
    text-align: center;
    line-height: 1;
    padding: 0 13%;
    margin-bottom: 25px;
}

@media screen and (max-width: 992px) {
    .bar-inner__page-title {
        font-size: 36px;
        padding: 0;
    }
}

@media screen and (max-width: 767px) {
    .bar-inner__page-title {
        font-size: 28px;
        padding-top: 10px;
    }
}

.bar-inner__page-main-background {
    width: 100%;
    display: block;
    margin: 0 auto;
}

@media screen and (max-width: 767px) {
    .bar-inner__page-main-background {
        max-height: 300px;
    }
}

.bar-inner__page-main-background img {
    width: 100%;
}

.bar-inner__page-info {
    max-width: 420px;
    width: 100%;
    margin: 0 auto;
    background-color: #fff;
    padding: 40px 0;
    position: relative;
    margin: -100px auto 15px;
}

@media screen and (max-width: 1280px) {
    .bar-inner__page-info {
        max-width: 375px;
    }
}

@media screen and (max-width: 767px) {
    .bar-inner__page-info {
        margin-top: -4px;
    }
}

.bar-inner__page-info-logo {
    width: 142px;
    height: 142px;
    margin: -107px auto 20px;
}

.bar-inner__page-info-logo img {
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 1280px) {

}

.bar-inner__page-info-description {
    padding-left: 40px;
    padding-right: 40px;
}

.bar-inner__page-info-description-address,
.bar-inner__page-info-description-timeofwork,
.bar-inner__page-info-description-contacts p {
    text-align: center;
    display: block;
    width: 100%;
    font-family: 'FuturaPT-Light';
    font-size: 16px;
    line-height: 1.4;
    letter-spacing: normal;
}

.bar-inner__page-content {
    max-width: 650px;
    width: 100%;
    margin: 0 auto;
    background-color: #fff;
    padding: 35px 100px 35px 130px;
    margin-bottom: 15px;
}

@media screen and (max-width: 1280px) {
    .bar-inner__page-content {
        max-width: 575px;
        padding: 35px 100px;
    }
}

@media screen and (max-width: 767px) {
    .bar-inner__page-content {
        padding: 15px 15px 15px 15px;
    }
}

.bar-inner__page-content-buttons {
    text-align: left;
    margin-bottom: 30px;
}

.bar-inner__page-content-buttons button {
    background-color: white;
    border: none;
    outline: none;
    width: 95px;
    height: 29px;
    font-family: 'FuturaPT-Regular';
    font-size: 14px;
    text-align: center;
    letter-spacing: 1.5px;
}

@media screen and (max-width: 332px) {
    .bar-inner__page-content-buttons button {
        width: 90px;
    }
}

.bar-inner__page-content-buttons .active, button:hover {
    background-color: black;
    color: white;
}

.bar-inner__page-content table {
    font-family: FuturaPT-Reg;
    font-size: 18px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: left;
}

.bar-inner__page-content .inner__text {
    width: 100%;
}

.page__container--bar-inner {
    .swiper-container {
        width: 200% !important;
        left: -50% !important;
    }
    
    .inner__slider{
        max-height: 700px !important;
    }
    
    .swiper-button-next,
    .swiper-button-prev,
    .inner__slider-count {
        margin-left: 50px;
    }
    
    .inner__slider-description {
        margin-left: 100px !important;
        padding-top: 5px;
        min-height: 28px;
    }
    
    .swiper {
        padding-top: 0px !important;
        padding-bottom: 0px !important;
    }
    
    
    @media screen and (max-width: 1280px) {
    
        .swiper-container {
            width: 200% !important;
            left: -70% !important;
        }
    
        .inner__slider {
            max-height: 600px !important;
        }
    
        .swiper-button-next,
        .swiper-button-prev,
        .inner__slider-count {
            margin-left: 30px;
        }
    
        .inner__slider .swiper-container {
            width: 190% !important;
            left: -45% !important;
        }
    }
    
    @media screen and (max-width: 992px) {
    
        .swiper-button-next,
        .swiper-button-prev,
        .inner__slider-count {
            margin-left: 0px;
        }
        .inner__slider{
            height: 550px !important;
        }
    
        .inner__slider-description {
            margin-left: 180px !important;
        }
    
        .inner__slider .swiper-container {
            width: 170% !important;
            left: -35% !important;
        }
    
    }
    
    @media screen and (max-width: 767px) {
    
        .inner__slider-count{
            left: 48px !important;
        }
    
        .inner__slider-description {
            margin-left: 110px !important;
        }
    
        .inner__slider {
            height: 370px !important;
        }
    
        .inner__slider .swiper-container {
            width: calc(100% + 20px) !important;
            left: -10px !important;
        }
    }
    
    @media screen and (max-width: 375px) {
        .inner__slider {
            height: 340px !important;
        }
    }
    
    @media screen and (max-width: 320px) {
        .inner__slider {
            height: 280px !important;
        }
    }
}





