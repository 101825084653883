.page__holder-background {
    width: 100%;
    max-height: 400px;
    -o-object-fit: cover;
       object-fit: cover;
}

@media screen and (max-width: 767px) {
    .page__top-holder {
        height: 290px;
    }
}

.header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 6px 0;
    background-color: #fff;
    height: 64px;
    padding: 0 20px;
}

.header__burger {
    display: none;
}

.subheader{
    border-top: 1px solid black;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.21);
    position: relative;
    z-index: 5;
}

.header-hide{
    display: none;
}


@media screen and (max-width: 992px) {
    .header__burger {
        display: block;
    }
    .subheader{
        display: none;
    }
}

.header__logo a {
    width: 100%;
    height: 100%;
    display: inline-block;
}


.header__logo a img {
    height: 75px;
}

@media screen and (max-width: 992px) {
    .header__logo {
        padding-left: 0px;
    }
}

@media screen and (max-width: 767px) {
    .header__logo a img {
        width: 70%;
        display: block;
        margin: 0 auto;
    }
}

.header__menu {
    padding-left: 20px;
    margin-right: auto;
}

@media screen and (max-width: 992px) {
    .header__menu {
        display: none;
    }
}

.header__menu li {
    display: inline-block;
    margin-right: 10px;
}

.header__menu li:last-of-type {
    margin-right: 0;
}

.header__menu li a {
    font-family: 'FuturaPT-Book';
    font-size: 13px;
    letter-spacing: 0.7px;
    color: #000;
    text-transform: uppercase;
    text-decoration: none;
    padding-bottom: 3px;
    border-bottom: 1px solid transparent;
    -webkit-transition: .3s;
    transition: .3s;
}

.header__menu li a:hover {
    border-bottom: 1px solid #000;
}

.header__search .fa-search,
.header__burger .fa-bars {
    font-size: 24px;
    cursor: pointer;
}

.header-fixed {
    position: fixed;
    z-index: 10;
}

.header-hidden {
    display: none;
}

.header-fixed .header__menu {
    display: none;
}

.header-fixed .header__burger {
    display: block;
}



.sub-inner,
.header-inner {
    max-width: 400px;
    width: 100%;
    height: 100%;
    background-color: #fff;
    position: fixed;
    left: 0;
    top: 0;
    -webkit-transform: translateX(-200%);
            transform: translateX(-200%);
            transform: translateX(-200%);
    -webkit-transition: .5s;
    transition: .5s;
    z-index: 100;
    overflow-y: auto;
    padding-bottom: 50px;
}

.sub-inner{
    border-left: 1px solid black;
    -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
}

.header-inner__logo img {
    max-width: 160px;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    display: block;
    margin: 0 auto;
}

.header-inner__menu {
    margin: 0 50px;
}

.header-inner__menu li {
    list-style: none;
}

.header-inner__link {
    font-family: 'FuturaPT-Book';
    font-size: 23px;
    letter-spacing: 1px;
    line-height: 75px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.21);
    display: inline-block;
    width: 100%;
    color: #000;
    text-decoration: none;
}

.header-inner__link-inherit{
    font-size: 16px;
    margin-left: 20%;
    width: 80%;
    line-height: 40px;

}

.header-inner__link:hover {
    text-decoration: underline;
}

.header-inner__close {
    width: 25px;
    height: 25px;
    margin: 50px auto 0;
    background: url('../img/icons/cross.svg') no-repeat;
    cursor: pointer;
}

.header__search-container {
    width: 100%;
    height: 100%;
    position: fixed;
    overflow: auto;
    left: 0;
    top: 0;
    z-index: 50;
    background: rgba(0, 0, 0, 0.7);
    display: none;
}

.header__body {
    max-width: 800px;
    margin: 0 auto;
    padding: 100px 0;
}

@media screen and (max-width: 992px) {
    .header__body {
        padding: 100px 40px;
    }
}

@media screen and (max-width: 767px) {
    .header__body {
        padding: 0 0 100px;
    }
}

.header__body-search {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    position: relative;
}

@media screen and (max-width: 767px) {
    .header__body-search {
        background-color: #fff;
    }
}

.header__body-input {
    font-size: 48px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    font-family: 'FuturaPT-Demi';
    text-transform: uppercase;
    width: 100%;
    padding-bottom: 10px;
    color: #fff;
}

@media screen and (max-width: 767px) {
    .header__body-input {
        color: rgba(0, 0, 0, 0.5);
        font-size: 18px;
        padding-left: 20px;
        padding-top: 20px;
        padding-bottom: 20px;
        text-transform: capitalize;
    }
}

.header__body-input::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.5);
    text-transform: uppercase;
    font-family: 'FuturaPT-Demi';
}

.header__body-input:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.5);
    text-transform: uppercase;
    font-family: 'FuturaPT-Demi';
}

.header__body-input::placeholder {
    color: rgba(255, 255, 255, 0.5);
    text-transform: uppercase;
    font-family: 'FuturaPT-Demi';
}

@media screen and (max-width: 767px) {
    .header__body-input::-webkit-input-placeholder {
        color: #000;
        opacity: .5;
        text-transform: capitalize;
    }
    .header__body-input:-ms-input-placeholder {
        color: #000;
        opacity: .5;
        text-transform: capitalize;
    }
    .header__body-input::placeholder {
        color: #000;
        opacity: .5;
        text-transform: capitalize;
    }
}

.header__body-search-icon {
    position: absolute;
    right: 0;
    font-size: 24px;
    color: #fff;
    cursor: pointer;
}

@media screen and (max-width: 767px) {
    .header__body-search-icon {
        color: rgba(0, 0, 0, 0.5);
        font-size: 18px;
        right: 20px;
    }
}

.header__body-close {
    width: 30px;
    height: 30px;
    margin: 35px auto 0;
    cursor: pointer;
}

@media screen and (max-width: 767px) {
    .header__body-close {
        width: 25px;
        height: 25px;
    }
}

.header__body-close img {
    width: 100%;
}

.header__body-notfound {
    text-align: center;
    font-size: 16px;
    font-family: 'FuturaPT-Demi';
    color: #fff;
    text-transform: uppercase;
    margin-top: 25px;
    line-height: 3;
    display: block;
}

@media screen and (max-width: 767px) {
    .header__body-notfound {
        font-size: 14px;
    }
}

.header__body-tabs {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    margin-top: 35px;
    display: none;
}

@media screen and (max-width: 767px) {
    .header__body-tabs {
        margin-top: 25px;
        line-height: 30px;
        -ms-flex-pack: distribute;
            justify-content: space-around;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    }
}

.header__body-tabs a {
    color: #fff;
    text-transform: uppercase;
    font-family: 'FuturaPT-Demi';
    font-size: 18px;
}

@media screen and (max-width: 767px) {
    .header__body-tabs a {
        font-size: 14px;
        padding: 0 20px;
    }
}

.header__body-tabs a:hover {
    text-decoration: underline;
}

.header__body-items {
    display: none;
}

.header__body-item {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 20px 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    width: 100%;
}

@media screen and (max-width: 767px) {
    .header__body-item {
        padding-left: 20px;
    }
}

.header__body-item img {
    max-width: 200px;
    max-height: 200px;
    min-height: 100px;
    margin-right: 20px;
}

@media screen and (max-width: 767px) {
    .header__body-item img {
        display: none;
    }
}


.header__body-itemtext .header__body-item p{
    font-family: 'Playfair-Display' !important;
}

.header__body-itempreview {
    font-size: 22px !important;
    font-family: 'Playfair-Display' !important;
    line-height: 1.45 !important;
}

@media screen and (max-width: 767px) {
    .header__body-itempreview {
        font-size: 16px !important;
    }
}

.header__body-beertype {
    font-family: 'FuturaPT-Book' !important;
    font-size: 24px !important;
    line-height: 1.33 !important;
}

@media screen and (max-width: 767px) {
    .header__body-beertype {
        font-size: 18px !important;
    }
}

.header__body-item p {
    font-size: 24px;
    line-height: 1.33;
    font-family: 'FuturaPT-ExtraBold';
    color: #fff;
}

@media screen and (max-width: 767px) {
    .header__body-item p {
        font-size: 18px;
    }
}