.beer__page {
    position: relative;
}

.beer__content {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    position: relative;
}
.beer__filters {
    background: #fff;
    -ms-flex-item-align: start;
        align-self: flex-start;
    max-width: 170px;
    width: 100%;
    font-family: 'FuturaPT-Book';
    padding: 14px 20px 25px;
    margin-right: 50px;
}

@media screen and (max-width: 992px) {
    .beer__filters {
        margin-right: 35px;
    }
}

@media screen and (max-width: 767px) {
    .beer__filters {
        position: absolute;
        right: 0;
        margin-right: 0;
        overflow: hidden;
        padding: 0;
        max-width: 100px;
    }
    .beer__filters-inner {
        display: none;
        min-height: 80vh;
    }
}

.beer__filters-fixed {
    position: fixed;
    top: 0;
    padding: 10px 10%;
    height: 100vh;
    max-width: none;
    left: 0;
    overflow: auto;
}
 
.beer__filters-title {
    letter-spacing: 1.7px;
    text-transform: uppercase;
    font-size: 16px;
    color: #000;
}

@media screen and (max-width: 767px) {
    .beer__filters-title {
        padding: 7px 20px 7px 10px;
        text-align: center;
        font-size: 14px;
        background: url('../img/icons/arrow2.svg') no-repeat;
        background-size: 5px;
        background-position-y: center;
        background-position-x: 95%;
        cursor: pointer;
    }

    .beer__filters-fixed .beer__filters-title {
        background: none;
    }
}

.beer__filters-item-title {
    font-family: 'FuturaPT-Bold';
    font-size: 11px;
    padding: 10px 13px 8px 0px;
    border-bottom: 1px solid rgba(151, 151, 151, 0.18);
    cursor: pointer;
}

@media screen and (max-width: 767px) {
    .beer__filters-item-title {
        font-size: 10px;
    }
}
 
.beer__filters-item-inner {
    background: rgba(216, 216, 216, 0.2);
    display: none;
}

.beer__filters-item-row {
    font-size: 11px;
    cursor: pointer;
    padding: 4px 0;
}

.beer__filters-item-row label {
    width: 100px;
    min-width: none;
    vertical-align: middle;
}

.beer__filters-count {
    padding-top: 13px;
}

@media screen and (max-width: 767px) {
    .beer__filters-count {
        display: none;
        padding: 0;
    }

    .beer__filters-fixed .beer__filters-count {
        margin-top: 10px;
        font-size: 12px;
    }
}

.beer__filters-close {
    width: 100%;
    height: 30px;
    background: url('../img/icons/cross.svg') center no-repeat;
    background-size: 30px;
    display: none;
}

@media screen and (max-width: 767px) {
    .beer__filters-fixed .beer__filters-close {
        display: block;
    }        
}


.beer__items {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    margin-bottom: 40px;
    width: calc(100% - 220px);
    -ms-flex-item-align: start;
        align-self: flex-start;
}

@media screen and (max-width: 992px) {
    .beer__items {
        width: 100%;
        margin-bottom: 30px;
    }
}

@media screen and (max-width: 767px) {
    .beer__items {
        margin-top: 45px;
    }
}

.beer__item {
    max-width: 210px;
    min-height: 300px;
    width: 100%;
    background: #fff;
    margin-bottom: 10px;
    margin-right: 8px;
    text-align: center;
    text-transform: uppercase;
}

@media screen and (max-width: 1240px) {
    .beer__item {
        max-width: 175px;
        min-height: 260px;
    }
}

@media screen and (max-width: 992px) {
    .beer__item {
        max-width: 165px;
        margin-right: 5px;
    }
}

@media screen and (max-width: 767px) {
    .beer__item {
        max-width: none;
        width: 49%;
        margin-right: 1%;
    }
}

.beer__item-type {
    font-family: 'FuturaPT-Demi';
    font-size: 8px;
    letter-spacing: 0.6px;
    width: 100%;
    padding: 20px 0 5px;
    color: #32a5b3;
}

.beer__item-image {
    max-height: 170px;
    display: block;
    margin: 0 auto;
}

@media screen and (max-width: 1240px) {
    .beer__item-image {
        max-height: 140px;
    }
}

.beer__item-title {
    font-family: 'FuturaPT-ExtraBold';
    font-size: 16px;
    letter-spacing: 0.4px;
    width: 100%;
    padding: 12px 0 0;
}

@media screen and (max-width: 767px) {
    .beer__item-title {
        font-size: 14px;
    }
}

.beer__item-subtitle {
    font-family: 'FuturaPT-Light';
    font-size: 12px;
    letter-spacing: 0.3px;
}

@media screen and (max-width: 767px) {
    .beer__item-subtitle {
        font-size: 10px;
    }
}

.beer__item-indicators {
    width: 100%;
    padding: 5px 10px;
    margin-top: 5px;
}

.beer__item-indicators-value {
    display: inline-block;
    vertical-align: top;
}

.beer__item-indicators-value:nth-child(2) {
    padding: 0 10px;
}

.beer__item-value-bold, .beer__item-value-normal {
    font-family: 'FuturaPT-Demi';
    font-size: 9px;
    letter-spacing: 0.8px;
}

.beer__item-value-normal {
    font-family: 'FuturaPT-Light';
}

.collections {
    margin: 0 0 40px;
    padding: 40px 45px;
    background-color: #fff;
}

@media screen and (max-width: 1240px) {
    .collections {
        padding: 35px;
    }
}

@media screen and (max-width: 992px) {
    .collections {
        padding: 30px;
        margin-bottom: 30px;
    }
}

@media screen and (max-width: 767px) {
    .collections {
        padding: 25px 15px;
    }
}

.collections__holder {
    max-width: 33%;
    width: 100%;
    height: 240px;
    background: grey;
    font-size: 30px;
    text-align: center;
    margin-right: 10px;
    display: inline-block;
    vertical-align: top;
}

@media screen and (max-width: 1240px) {
    .collections__holder {
        max-width: 300px;
        height: 185px;
        width: 100%;
    }
}

@media screen and (max-width: 992px) {
    .collections__holder {
        display: block;
        margin: 0 auto 20px;
    }
}

.collections__content {
    display: inline-block;
    vertical-align: top;
    width: 65%;
}

@media screen and (max-width: 1240px) {
    .collections__content {
        width: calc(100% - 300px - 15px);
    }
}

@media screen and (max-width: 992px) {
    .collections__content {
        width: 100%;
    }
}

.collections__title {
    font-family: 'FuturaPT-Demi';
    font-size: 16px;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-bottom: 12px;
}

@media screen and (max-width: 1240px) {
    .collections__title {
        font-size: 16px;
        margin-bottom: 7px;
    }
}

@media screen and (max-width: 767px) {
    .collections__title {
        font-size: 10px;
    }
}

.collections__big-title {
    font-family: 'FuturaPT-ExtraBold';
    font-size: 48px;
    line-height: 54px;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-bottom: 10px;
}

@media screen and (max-width: 1240px) {
    .collections__big-title {
        font-size: 35px;
    }
}

@media screen and (max-width: 992px) {
    .collections__big-title {
        line-height: 45px;
        margin-bottom: 5px;
    }
}

.collections__big-title a {
    color: #000;
    text-decoration: underline;
}

.collections__preview {
    font-family: 'Playfair-Display';
    font-size: 22px;
    line-height: 34px;
    letter-spacing: 1px;
    max-width: 85%;
}

@media screen and (max-width: 1240px) {
    .collections__preview {
        max-width: 100%;
        font-size: 18px;
        line-height: 28px;
    }
}

.page__container--beer {
    .read-also {
        padding-bottom: 30px;
    }
    
    .read-also__items {
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        width: 100%;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
        -ms-flex-negative: 4;
            flex-shrink: 4;
    }
    
    .read-also__item {
        width: 24.5%;
        text-align: left;
        padding: 45px;
        background: #fff;
        margin-bottom: 10px;
    }
    
    @media screen and (max-width: 1240px) {
        .xl {
            padding: 30px;
        }
    
        .read-also__item {
            padding: 20px;
        }
    }
    
    @media screen and (max-width: 767px) {
        .read-also__item {
            padding: 10px;
            width: 100%;
            text-align: left;
        }
    }
    
    .xl {
        width: 32%;
    }
    
    @media screen and (max-width: 767px) {
        .xl {
            width: 49%;
        }
    }
    
    @media screen and (max-width: 767px) {
        .read-also__item-image {
            max-width: 37%;
            height: auto;
            float: left;
            margin-right: 5px;
        }
    }
    
    @media screen and (max-width: 767px) {
        .xl .read-also__item-image {
            margin: 0 auto;
            float: none;
            max-width: none;
        }
    }
    
    .read-also__item-magazine {
        font-family: 'FuturaPT-Bold';
        font-size: 12px;
        letter-spacing: 1px;
        padding-bottom: 5px;
        text-transform: uppercase;
    }
    
    @media screen and (max-width: 767px) {
        .read-also__item-magazine {
            text-align: center;
            padding-bottom: 10px;
        }
    }
    
    .read-also__item-title {
        font-family: 'FuturaPT-Demi';
        font-size: 24px;
        line-height: 1.3;
        text-transform: uppercase;
        letter-spacing: 0.5px;
    }
    
    @media screen and (max-width: 1240px) {
    
        .read-also__item .read-also__item-title {
            font-size: 20px;
        }
        .xl .read-also__item-title {
            font-size: 22px;
            text-align: left;
        }
    }
    
    @media screen and (max-width: 767px) {
        .read-also__item .read-also__item-title {
            font-size: 12px;
        }
        .xl .read-also__item-title {
            font-size: 14px;
        }
    }
    
    .read-also__item-description {
        font-family: 'Playfair-Display';
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 1px;
    }
    
    @media screen and (max-width: 1240px) {
        .read-also__item-description {
            font-size: 13px;
            line-height: 20px;
        }
    }
    
    @media screen and (max-width: 767px) {
        .read-also__item-description {
            font-size: 9px;
            line-height: 13px;
            letter-spacing: 0.7px;
        }
    }
}