.digest {
    padding: 35px 0 40px;
    border: 1px solid #000;
    text-align: center;
    margin-bottom: 40px;
}

.digest__title {
    font-family: 'FuturaPT-Bold';
    letter-spacing: 2.9px;
    font-size: 28px;
    text-transform: uppercase;
    padding-bottom: 13px;
}

@media screen and (max-width: 767px) {
    .digest__title {
        font-size: 16px;
        padding-bottom: 8px;
    }
}

.digest__subtitle {
    font-size: 16px;
    font-family: 'FuturaPT-Book';
    padding-bottom: 25px;
}

@media screen and (max-width: 767px) {
    .digest__subtitle {
        font-size: 12px;
    }
}

.digest__form-background {
    max-width: 640px;
    margin: 0 auto;
    position: relative;
    background: transparent;
    border: 2px solid black;
    padding: 0;
    -webkit-box-shadow: -6px 5px 0px 0px black;
            box-shadow: -6px 5px 0px 0px black;
}

@media screen and (max-width: 992px) {
    .digest__form-background {
        max-width: 480px;
    }
}

@media screen and (max-width: 767px) {
    .digest__form-background {
        max-width: 290px;
    }
}

.digest__form-background::before {
    content: '';
    display: block;
    position: absolute;
    z-index: -1;
    top: -2px;
    left: -8px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 5px 6px;
    border-color: transparent transparent black transparent;
  }

  .digest__form-background::after {
    content: '';
    display: block;
    position: absolute;
    z-index: -1;
    bottom: -7px;
    right: -2px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 6px 0 0;
    border-color: black transparent transparent transparent;
  }

.digest__input {
    border: none;
    padding: 20px 0 20px 15px;
    position: relative;
    background: transparent;
    display: inline-block;
    vertical-align: middle;
    width: 77.5%;
    margin-right: -6px;
}

@media screen and (max-width: 992px) {
    .digest__input {
        width: 70%;
    }
}

@media screen and (max-width: 767px) {
    .digest__input {
        width: 65%;
        padding: 17px 0 17px 15px;
    }
}

.digest__submit {
    border-left: 2px solid #000;
    width: calc(100% - 77.5%);
    position: relative;
}

.digest__submit::after {
    content: '';
    position: absolute;
    right: -2px;
    top: 0;
    width: 2px;
    height: 100%;
    background-color: #000;
}

@media screen and (max-width: 992px) {
    .digest__submit {
        width: calc(100% - 70%);
    }
}

@media screen and (max-width: 767px) {
    .digest__submit {
        width: calc(100% - 65%);
        padding: 20px 7px;
        font-size: 12px;
    }
}