.rubric__photo {
    padding: 50px 65px 0;
    max-width: 100%;
    display: block;
    margin: 0 auto;
}

@media screen and (max-width: 1280px) {
    .rubric__photo {
        padding-left: 44px;
        padding-right: 44px;
        padding-top: 35px;
    }
    .fest-small{
        width: 100%;
        margin: 0px 0px 50px !important;
    }
}


@media screen and (max-width: 992px) {
    .rubric__photo {
        padding-left: 28px;
        padding-right: 28px;
    }
}


@media screen and (max-width: 767px) {
    .rubric__photo {
        padding-left: 0;
        padding-right: 0;
        padding-top: 15px;
    }
}

.rubric__content {
    background-color: #fff;
    max-width: 650px;
    margin: -150px auto 45px;
    padding: 25px 20px 35px;
    text-align: center;
    position: relative;
}


@media screen and (max-width: 992px) {
    .rubric__content {
        max-width: 575px;
    }
}

@media screen and (max-width: 767px) {
    .rubric__content {
        margin-top: -5px;
        padding: 15px 10px 25px;
    }
}

.rubric__title {
    font-family: 'FuturaPT-ExtraBold';
    font-size: 48px;
    line-height: 1.15;
    letter-spacing: 1px;
    text-decoration: underline;
    color: #000;
}

@-moz-document url-prefix() {
  .rubric__title {
    font-synthesis: style;
  }
}


@media screen and (max-width: 1280px) {
    .rubric__title {
        font-size: 36px;
        letter-spacing: 0.8px;
    }
}

@media screen and (max-width: 992px) {
    .rubric__title {
        font-size: 35px;
    }
}

@media screen and (max-width: 767px) {
    .rubric__title {
        font-size: 28px;
        letter-spacing: 0.6px;
    }
}

.rubric__description {
    font-family: 'Playfair-Display';
    font-size: 22px;
    line-height: 1.5;
    padding: 25px 27px;
}

@media screen and (max-width: 1280px) {
    .rubric__description {
        font-size: 18px;
        letter-spacing: 0.8px;
        padding: 10px;
    }
}

@media screen and (max-width: 767px) {
    .rubric__description {
        font-size: 14px;
        letter-spacing: 1px;
    }
}

.rubric__author {
    font-family: 'FuturaPT-Bold';
    font-size: 14px;
    letter-spacing: 1px;
    text-transform: uppercase;
}

@media screen and (max-width: 767px) {
    .rubric__author {
        font-size: 10px;
        letter-spacing: 0.7px;
    }
}

.rubric__author span {
    color: #f7c82e;
}

.rubric__read-also .read-also__item {
    text-align: left;
}

.rubric__banner {
    width: 100%;
    margin: 10px auto 20px;
}

.rubric__banner img {
    width: 100%;
    max-height: 500px;
}


.page__container--rubric .inner__text{
    width: unset;
    margin-bottom: 30px;
}


.page__container--rubric .inner__text p{
    font-family: 'FuturaPT-Book';
    font-size: 16px;
    line-height: 1.8;
    text-align: center;
}

.page__container--rubric .inner__text-title{
    text-align: center;
}


@media screen and (max-width: 992px) {
    .page__container--rubric .inner__text p {
        font-size: 14px;
    }
}

@media screen and (max-width: 767px) {
    .page__container--rubric .inner__text p {
        font-size: 12px;
    }
}


.fest-small {
    display: inline-block;
    text-align: center;
    height: 630px;
    margin: 50px 65px 50px;
    background: url('../img/cdfest.jpg') no-repeat center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    }


.border{
    position: relative;
    top: 100px;
}



.counter__sub{
    transform: translateY(-90%);
    font-family: 'FuturaPT-Light';
    font-size: 140px;
    padding-bottom: 10px;
    letter-spacing: 2.9px;
    color: white;
    text-transform: uppercase;
    padding-left: 30px;
    padding-right: 30px;
}
.counter__day{
    font-family: 'FuturaPT-Bold';
    transform: translateY(-450%);
    font-size: 25px
}


.counter__phrase{
    color: white;
    position: relative;
    top: -75px;
    text-transform: unset;
}

.Oval {
    position: relative;
    top: 100px;
    width: 289px;
    height: 289px;
    object-fit: contain;
}

.buy__button{
    position: relative;
    top: -45px;
}

@media screen and (max-width: 767px) {
    .fest-small {
        height: 500px;
    }
    .counter__sub{
        transform: translateY(-100%);
    }
    .counter__day{
        transform: translateY(-480%);
    }

    .Oval{
        top: 70px;
    }

    .counter__phrase{
        top: -130px;
    }

    .buy__button{
       top: -125px;
    }
}
