.author__page {
    display: inline-block;
    text-align: center;
    height: 351px;
    width: 100%;
    background: url('../img/cdfest.jpg') no-repeat center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.author-photo {
    position: relative;
    top: 50px;
}

.author-photo img {
    border-radius: 50%;
}

@media screen and (max-width: 767px) {
    .author__page {
        height: 290px;
    }

    .author-photo img {
        width: 186px;
        height: 186px;
    }
}

.author-inner__page {
    align-items: center;
}

.author-role {
    font-family: 'FuturaPT-Demi';
    font-size: 12px;
    line-height: 4.5;
    letter-spacing: 0.8px;
    text-align: center;
    text-transform: uppercase;
}

.author-name {
    font-family: 'FuturaPT-Bold';
    font-size: 36px;
    font-weight: 800;
    line-height: 1.33;
    letter-spacing: 1px;
    text-align: center;
    text-transform: uppercase;
    padding-bottom: 30px;
}

.author-bio {
    margin-left: auto;
    margin-right: auto;
    max-width: 655px;
    font-family: 'FuturaPT-Book';
    font-size: 16px;
    line-height: 1.88;
    text-align: left;
    text-transform: uppercase;
    padding-bottom: 30px;
}
