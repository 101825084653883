.enter-association {
    padding: 40px 110px;
    border: 1px solid #000;
    margin-bottom: 20px;
}

@media screen and (max-width: 1340px) {
    .enter-association {
        padding: 40px 20px;
    }
}

.enter-association__title {
    font-family: 'FuturaPT-Bold';
    font-size: 28px;
    letter-spacing: 2.9px;
    text-transform: uppercase;
    padding-bottom: 15px;
    text-align: center;
}

@media screen and (max-width: 767px) {
    .enter-association__title {
        font-size: 18px;
        line-height: 1.22;
        padding-bottom: 25px;
    }
}

.enter-association__input,
.enter-association__textarea {
    display: block;
    padding: 14px 20px;
    font-family: 'FuturaPT-Book';
    width: 100%;
}

.enter-association__textarea {
    height: 152px;
    resize: none;
}

@media screen and (max-width: 992px) {
    .enter-association__textarea {
        height: 100px;
    }
}

.enter-association__input::-webkit-input-placeholder {
    font-size: 15px;
    line-height: 1.2;
    letter-spacing: -0.2px;
    color: rgba(71, 71, 71, 0.25);
    text-transform: uppercase;
}

.enter-association__input:-ms-input-placeholder {
    font-size: 15px;
    line-height: 1.2;
    letter-spacing: -0.2px;
    color: rgba(71, 71, 71, 0.25);
    text-transform: uppercase;
}

.enter-association__input::placeholder {
    font-size: 15px;
    line-height: 1.2;
    letter-spacing: -0.2px;
    color: rgba(71, 71, 71, 0.25);
    text-transform: uppercase;
}

.enter-association__textarea::-webkit-input-placeholder {
    font-size: 15px;
    line-height: 1.2;
    letter-spacing: -0.2px;
    color: rgba(71, 71, 71, 0.25);
    text-transform: uppercase;
}

.enter-association__textarea:-ms-input-placeholder {
    font-size: 15px;
    line-height: 1.2;
    letter-spacing: -0.2px;
    color: rgba(71, 71, 71, 0.25);
    text-transform: uppercase;
}

.enter-association__textarea::placeholder {
    font-size: 15px;
    line-height: 1.2;
    letter-spacing: -0.2px;
    color: rgba(71, 71, 71, 0.25);
    text-transform: uppercase;
}

.button__enter-association {
    max-width: 300px;
}

@media screen and (max-width: 767px) {
    .button__enter-association {
        max-width: none;
        width: 100%;
    }
}

.bottom-inputs {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}

@media screen and (max-width: 992px) {
    .bottom-inputs {
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    }
}

.inputs {
    width: 66%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
}

@media screen and (max-width: 992px) {
    .inputs {
        width: 100%;
        margin: 0 30px;
    }
}

@media screen and (max-width: 767px) {
    .inputs {
        margin: 0;
    }
}

.input {
    width: 320px;
    margin-bottom: 30px;
}

@media screen and (max-width: 1170px) {
    .input {
        width: 280px;
    }
}

@media screen and (max-width: 992px) {
    .input {
        width: 48%;
    }

    .input__textarea {
        width: 100%;
    }
}

@media screen and (max-width: 767px) {
    .input {
        width: 100%;
    }
}

.input__xl,
.label__xl {
    width: 100%;
    max-width: 425px;
    margin: 0 auto;
    display: block;
}

.input__fullwide {
    width: 100%;
}

@media screen and (max-width: 992px) {
    .comment {
        width: 100%;
        max-width: 320px;
        margin: 0 auto;
    }
}

@media screen and (max-width: 767px) {
    .comment {
        max-width: none;
    }
}