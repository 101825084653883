@media screen and (max-width: 767px) {
  .inner__page .page__container {
    padding-left: 0px;
    padding-right: 0px;
  }
}

.inner__page-title {
  font-family: 'FuturaPT-ExtraBold';
  font-size: 48px;
  letter-spacing: 1px;
  text-align: center;
  line-height: 1;
  padding: 0 13%;
  margin-bottom: 25px;
}

@media screen and (max-width: 992px) {
  .inner__page-title {
    font-size: 36px;
    padding: 0;
  }
}

@media screen and (max-width: 767px) {
  .inner__page-title {
    font-size: 28px;
    padding-top: 10px;
  }
}

.inner__page-main-background {
  width: 100%;
  display: block;
  margin: 0 auto;
}

@media screen and (max-width: 767px) {
  .inner__page-main-background {
    max-height: 300px;
  }
}

.inner__page-main-background img {
  width: 100%;
}

.inner__page-content {
  padding: 45px 55px 35px;
  margin: -60px 15% 35px;
  background-color: #fff;
}

@media screen and (max-width: 1280px) {
  .inner__page-content {
    margin: -60px 5% 35px;
  }
}

@media screen and (max-width: 992px) {
  .inner__page-content {
    padding: 45px 15px 35px;
  }
}

@media screen and (max-width: 767px) {
  .inner__page-content {
    padding: 20px 10px;
    margin: 0 10px 35px;
  }
}


.inner__page-content img {
  position: relative;
  width: 130%;
  left: -15%;
  margin: 10px auto;
}

@media screen and (max-width: 992px) {
  .inner__page-content img {
    width: 100%;
    left: 0;
  }
}

.inner__content-preview {
  font-family: 'Playfair-Display';
  font-weight: bold;
  font-size: 26px;
  line-height: 1.4;
  color: #9b9b9b;
  text-align: center;
  border-bottom: 1px solid #979797;
  padding: 0 35px 35px;
  margin-bottom: 35px;
  letter-spacing: 1px;
}

.inner__content-preview p {
  font-family: 'Playfair-Display';
}

@media screen and (max-width: 1280px) {
  .inner__content-preview {
    padding: 0 5px 35px;
  }
}

@media screen and (max-width: 992px) {
  .inner__content-preview {
    font-size: 24px;
    padding-bottom: 30px;
  }
}

@media screen and (max-width: 767px) {
  .inner__content-preview {
    font-size: 18px;
    line-height: 1.5;
  }
}

.inner__author {
  max-width: 155px;
  margin-top: 40px;
  display: inline-block;
}

@media screen and (max-width: 767px) {
  .inner__author {
    max-width: none;
    width: 100%;
  }
  .inner__author-content {
    display: inline-block;
    vertical-align: top;
  }
  .inner__share {
    vertical-align: top;
    float: right;
  }
}

.inner__author-title {
  font-family: 'FuturaPT-Bold';
  font-size: 14px;
  line-height: 1.25;
  letter-spacing: 1px;
}

@media screen and (max-width: 767px) {
  .inner__author-title {
    font-size: 12px;
  }
}

.inner__author-name {
  font-family: 'FuturaPT-Light';
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 1.25;
}

@media screen and (max-width: 767px) {
  .inner__author-name {
    font-size: 12px;
  }
}

.inner__author-date {
  font-family: 'FuturaPT-Light';
  font-size: 11px;
  line-height: 1.6;
  margin-bottom: 35px;
}

@media screen and (max-width: 767px) {
  .inner__author-date {
    font-size: 9px;
  }
}

.inner__share-title {
  font-family: 'Playfair-Display';
  font-weight: bold;
  font-size: 12px;
  line-height: 1.5;
  letter-spacing: 1px;
  text-transform: uppercase;
}

@media screen and (max-width: 767px) {
  .inner__share-title {
    font-size: 9px;
  }
}

.inner__share-item {
  display: inline-block;
  padding-right: 7px;
}

.inner__share-item a {
  width: 20px;
  height: 40px;
  display: inline-block;
}

@media screen and (max-width: 767px) {
  .inner__text-share-item a svg {
    width: 20px;
  }
  .inner__text-facebook a svg {
    width: 9px;
  }
}

.inner__share-item--facebook {
  background: url('../img/icons/facebook-black.svg') center no-repeat;
  background-size: 8px;
}

.inner__share-item--vk {
  background: url('../img/icons/vk-black.svg') center no-repeat;
  background-size: contain;
}

.inner__share-item--instagram {
  background: url('../img/icons/instagram-black.svg') center no-repeat;
  background-size: 15px;
}

.inner__share-item--telegram {
  background: url('../img/icons/telegram-black.svg') center no-repeat;
  background-size: 15px;
}

.inner__text .rich-text p {
  margin-bottom: 20px;
}

.inner__text-quote {
  font-size: 35px;
  float: left;
  margin-left: -160px;
  margin-bottom: 5px;
  font-family: 'FuturaPT-ExtraBold';
  line-height: 1.2;
  letter-spacing: 1px;
  text-decoration: underline;
  max-width: 435px;
  background: #fff;
}

@media screen and (max-width: 1280px) {
  .inner__text-quote {
    font-size: 33px;
  }
}

@media screen and (max-width: 992px) {
  .inner__text-quote {
    font-size: 30px;
    max-width: 360px;
  }
}

@media screen and (max-width: 767px) {
  .inner__text-quote {
    font-size: 26px;
    max-width: none;
    width: 100%;
    margin-left: auto;
    margin-bottom: 15px;
  }
}

.inner__notice {
  display: block;
  max-width: 1050px;
  width: 100%;
  clear: both;
  position: relative;
  background-color: #f7c82e;
  border-left: 1px solid #f7c82e;
  border-right: 1px solid #f7c82e;
}

@media screen and (max-width: 767px) {
  .inner__notice {
    padding: 0 15px;
    margin-bottom: 10px;
    border: none;
  }
}

.inner__notice::before {
  content: '';
  position: absolute;
  left: -15%;
  top: 0;
  height: 100%;
  width: 15%;
  background-color: #f7c82e;
}

.inner__notice::after {
  content: '';
  position: absolute;
  right: -15%;
  top: 0;
  height: 100%;
  width: 15%;
  background-color: #f7c82e;
}

@media screen and (max-width: 992px) {
  .inner__notice::before {
    left: -7%;
  }
  .inner__notice::after {
    right: -7%;
  }
  .inner__notice::before,
  .inner__notice::after {
    width: 7%;
  }
}

@media screen and (max-width: 767px) {
  .inner__notice::before {
    left: -10px;
  }
  .inner__notice::after {
    right: -10px;
  }
  .inner__notice::before,
  .inner__notice::after {
    width: 10px;
  }
}

.inner__notice-author {
  max-width: 155px;
  display: inline-block;
  vertical-align: top;
  margin-top: 75px;
  color: #fff;
}

@media screen and (max-width: 767px) {
  .inner__notice-author {
    max-width: none;
    width: 100%;
    margin-top: 20px;
  }
}

.inner__notice-author-photo {
  max-width: 50px;
  max-height: 50px;
  display: inline-block;
  vertical-align: middle;
}

.inner__notice-author span {
  font-family: 'FuturaPT-Book';
  font-size: 14px;
  color: #fff;
  letter-spacing: 1px;
}

.inner__notice-text {
  width: calc(100% - 210px);
  margin-left: 50px;
  display: inline-block;
  vertical-align: top;
  background-color: #f7c82e;
}

@media screen and (max-width: 767px) {
  .inner__notice-text {
    width: 100%;
    margin-left: auto;
  }
}

.inner__notice-text p {
  font-family: 'FuturaPT-Book';
  font-size: 16px;
  line-height: 1.4;
  margin-bottom: 10px;
  color: #fff;
}

.inner__notice-text-title {
  font-family: 'FuturaPT-ExtraBold';
  font-size: 18px;
  line-height: 1.6;
  padding: 25px 0 15px;
  color: #fff;
}

.inner__slider {
  width: 100%;
  position: relative;
  clear: both;
  height: 750px;
  max-height: 750px;
}

.inner__slider img {
  height: auto;
  max-height: 700px;
  width: 100%;
}

@media screen and (max-width: 992px) {
  .inner__slider img {
    max-height: 480px;
  }
}

.page__container--inner {
  .swiper{
    padding-bottom: 0px;
    padding-top: 0px !important;
  }
}

.inner__slider .swiper-container {
  position: absolute;
  width: 130%;
  left: -15%;
  max-width: 1050px;
}

@media screen and (max-width: 1280px) {
  .inner__slider{
    height: 700px;
  }

  .inner__slider .swiper-container {
    width: 124%;
    left: -12%;
  }
}

@media screen and (max-width: 992px) {
  .inner__slider {
    height: 520px;
  }
  .inner__slider .swiper-container {
    width: 110%;
    left: -5%;
  }
}

@media screen and (max-width: 767px) {
  .inner__slider {
    height: 600px;
  }
  .inner__slider img {
    max-height: 300px;
  }
  .inner__slider .swiper-container {
    width: calc(100% + 20px);
    left: -10px;
  }
}

@media screen and (max-width: 480px) {
  .inner__slider {
    height: 320px;
  }
}

@media screen and (max-width: 375px) {
    .inner__slider {
        height: 290px;
    }
}

@media screen and (max-width: 360px) {
  .inner__slider {
    height: 260px;
  }
}



@media screen and (min-width: 992px){
  .page__container--inner .swiper-wrapper{
   right: -15%;
  }
}

.page__container--inner {
  .swiper-button-prev,
  .swiper-button-next {
    background: none !important;
    top: auto !important;
    bottom: 2px !important;
    left: 75px !important;
    height: auto !important;
  }

  .swiper-button-next {
    left: 130px !important;
  }
}

.inner__slider-count,
.inner__slider-description {
  font-family: 'FuturaPT-Light';
  font-size: 12px;
  line-height: 2.2;
}

.inner__slider-count {
  position: absolute;
  bottom: 0;
  left: 102px;
}

.inner__slider-description {
  margin-left: 20px;
  height: 29px;
}

.twitter-widget {
  height: 500px;
  background-color: grey;
}

@media screen and (max-width: 992px) {
  .twitter-widget {
    width: 100%;
  }

  .inner__slider-description{
    margin-left: 160px;
  }
}

@media screen and (max-width: 767px) {
  .twitter-widget {
    margin-top: 40px;
    height: 350px;
  }
}


@media screen and (max-width: 480px) {
  .page__container--inner .swiper-button-prev {
    left: 20px !important;
  }
  .inner__slider-count {
    left: 38px;
  }
  .page__container--inner .swiper-button-next {
    left: 75px !important;
  }

  .inner__slider-description {
    margin-left: 105px;
  }
}

