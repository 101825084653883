.association-inner__content {
    max-width: 650px;
    width: 100%;
    margin: 0 auto;
    background-color: #fff;
    padding: 40px 0;
    margin-bottom: 10px;
}

@media screen and (max-width: 1280px) {
    .association-inner__content {
        max-width: 575px;
        padding: 35px 0 40px;
    }
}

@media screen and (max-width: 767px) {
    .association-inner__content {
        padding: 20px;
    }
}

.association-inner__content-bottom {
    padding: 35px 100px 35px 130px;
    margin-bottom: 15px;
}

@media screen and (max-width: 1280px) {
    .association-inner__content-bottom {
        padding: 35px 100px;
    }
}

@media screen and (max-width: 767px) {
    .association-inner__content-bottom {
        padding: 15px;
    }
}

.association-inner__image {
    width: 100%;
    position: relative;
    padding-bottom: 30px;
}

.association-inner__image img {
    max-width: 260px;
    display: block;
    margin: 0 auto;
}

@media screen and (max-width: 767px) {
    .association-inner__image img {
        max-width: 200px;
    }
}

.association-inner__info {
    text-align: center;
}

.association-inner__date {
    font-family: 'FuturaPT-Demi';
    font-size: 12px;
    letter-spacing: 0.8px;
    text-transform: uppercase;
}

@media screen and (max-width: 767px) {
    .association-inner__date {
        font-size: 10px;
    }
}

.association-inner__title {
    font-family: 'FuturaPT-ExtraBold';
    font-size: 36px;
    line-height: 1.7;
    text-transform: uppercase;
}

@media screen and (max-width: 767px) {
    .association-inner__title {
        font-size: 28px;
    }
}

.association-inner__contacts {
    width: 100%;
}

.association-inner__contacts a {
    display: block;
    width: 100%;
    font-family: 'FuturaPT-Light';
    font-size: 16px;
    line-height: 1.4;
    letter-spacing: 1px;
}

@media screen and (max-width: 767px) {
    .association-inner__contacts a {
        font-size: 10px;
    }
}

.association-inner__content-title {
    font-family: 'FuturaPT-Demi';
    font-size: 18px;
    line-height: 1.6;
    padding-bottom: 9px;
}

@media screen and (max-width: 1280px) {
    .association-inner__content-title {
        font-size: 16px;
    }
}

.association-inner__description p {
    font-family: 'Playfair-Display';
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 15px;
}

@media screen and (max-width: 1280px) {
    .association-inner__description p {
        font-size: 14px;
        line-height: 1.9;
    }
}

.association-inner__team-items {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.association-inner__team-item {
    width: 90px;
    margin-right: 5px;
}

.association-inner__team-item img {
    width: 100%;
}

.association-inner__team-item span {
    display: block;
    font-family: 'FuturaPT-Book';
    font-size: 14px;
    line-height: 1.1;
}

.association-inner__slider {
    background-color: #b82c46;
}

.association-inner__slider .swiper-slide {
    text-align: center;
}

.association-inner__slider .swiper-slide a img {
    display: inline-block;
    height: auto;
    max-width: 100%;
}

@media screen and (max-width: 992px) {
    .association-inner__slider .swiper-slide a img {
        width: 100%;
    }
}

@media screen and (max-width: 767px) {
    .association-inner__slider .swiper-slide a img {
        width: auto;
    }
}

.association-inner__slider .swiper-button-prev .fa,
.association-inner__slider .swiper-button-next .fa {
    color: #fff;
}

.association-inner__slider-description {
    margin-top: -100px;
    margin-bottom: 100px;
}

@media screen and (max-width: 767px) {
    .association-inner__slider-description {
        margin-top: 0;
    }
}

.slider-content {
    text-align: center;
    padding: 0 20px 15px;
    max-width: 650px;
    width: 100%;
    margin: 0 auto;
    background-color: #fff;
}

@media screen and (max-width: 767px) {
    .slider-content {
        width: 100%;
        max-width: none;
        padding: 0 15px 15px;
    }
}

.slider-content__title {
    font-family: 'FuturaPT-Demi';
    font-size: 16px;
    line-height: 3.2;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #b82c46;
}

@media screen and (max-width: 767px) {
    .slider-content__title {
        font-size: 10px;
    }
}

.slider-content__name {
    font-family: 'FuturaPT-ExtraBold';
    font-size: 36px;
    line-height: 1.3;
    letter-spacing: 1px;
    text-transform: uppercase;
    padding-bottom: 5px;
}

@media screen and (max-width: 767px) {
    .slider-content__name {
        font-size: 28px;
    }
}

.slider-content__subtitle {
    font-family: 'FuturaPT-Light';
    font-size: 36px;
    line-height: 1.3;
    text-transform: uppercase;
    border-top: 1px solid rgba(0, 0, 0, 0.3);
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    padding: 13px 0;
}

@media screen and (max-width: 767px) {
    .slider-content__subtitle {
        font-size: 28px;
        padding: 7px 0;
    }
}

.slider-content .beer__item-value-normal,
.slider-content .beer__item-value-bold {
    font-size: 16px;
}

@media screen and (max-width: 767px) {
    .slider-content .beer__item-value-normal,
    .slider-content .beer__item-value-bold {
        font-size: 10px;
    }
}