.bar__page {
    position: relative;
}

.bar__map {
    width: 100%;
    height: 400px;
    margin-bottom: 45px;
}

.bar__wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-bottom: 15px;
}

@media screen and (max-width: 992px) {
    .bar__wrapper {
        -ms-flex-pack: distribute;
        justify-content: space-around;
    }
}

.bar__item {
    width: calc(20% - 10px);
    margin: 0px 10px 10px 0;
    padding: 45px;
    background: #fff;
    text-align: center;
}

@media screen and (max-width: 1280px) {
    .bar__item {
        padding: 35px 30px;
    }
}

@media screen and (max-width: 992px) {
    .bar__item {
        width: calc(25% - 10px);
    }
}

@media screen and (max-width: 767px) {
    .bar__item {
        width: calc(50% - 10px);
        padding: 15px;
    }
}

.bar__image {
    max-height: 160px;
    height: auto;
    margin: 0 auto 5px;
}

/*@media screen and (max-width: 1024px) {*/
/*.bar__image {*/
/*width: 120px;*/
/*height: 120px;*/
/*}*/
/*}*/

@media screen and (max-width: 767px) {
    .bar__image {
        max-height: unset;
    }
}

.bar__image img {
    width: 100%;
    height: 100%;
}

.bar__title {
    font-size: 16px;
    font-family: 'FuturaPT-ExtraBold';
    text-transform: uppercase;
    margin-bottom: 5px;
}

@media screen and (max-width: 767px) {
    .bar__title {
        font-size: 14px;
    }
}

.bar__address, .bar__timeofwork {
    font-size: 13px;
    font-family: 'FuturaPT-Light';
    text-align: center;
    margin-bottom: 5px;
}

@media screen and (max-width: 767px) {
    .bar__address, .bar__timeofwork {
        font-size: 10px;
    }
}