.beer-inner__content {
  max-width: 755px;
  padding: 35px 85px;
  background-color: #fff;
  margin: 0 auto 15px;
}

@media screen and (max-width: 992px) {
  .beer-inner__content {
    max-width: 570px;
  }
}

@media screen and (max-width: 767px) {
  .beer-inner__content {
    max-width: none;
    margin: 0 15px 10px;
    padding: 20px;
  }
}

.beer-inner__image {
  text-align: center;
}

.beer-inner__image img {
  max-width: 480px;
  max-height: 400px;
  display: inline-block;
  vertical-align: middle;
}

@media screen and (max-width: 480px) {
  .beer-inner__image img {
    max-width: 100%;
  }
}

.beer-inner__page .beer__item-indicators {
  text-align: center;
}

.beer-inner__page .beer__item-value-bold,
.beer-inner__page .beer__item-value-normal {
  font-size: 16px;
}

.beer-inner__page .beer__item-indicators-value:nth-child(2) {
  padding: 0 25px;
}

@media screen and (max-width: 767px) {
  .beer-inner__page .beer__item-value-bold,
  .beer-inner__page .beer__item-value-normal {
    font-size: 10px;
  }
  .beer-inner__page .beer__item-indicators-value:nth-child(2) {
    padding: 0 15px;
  }
}

.beer-inner__type {
  text-align: center;
  font-size: 16px;
  letter-spacing: 1px;
  font-family: 'FuturaPT-Demi';
  text-transform: uppercase;
  color: #32a5b3;
}

@media screen and (max-width: 767px) {
  .beer-inner__type {
    font-size: 10px;
  }
}

.beer-inner__title {
  text-align: center;
  font-family: 'FuturaPT-ExtraBold';
  font-size: 36px;
  line-height: 1.3;
  text-transform: uppercase;
}

@media screen and (max-width: 767px) {
  .beer-inner__title {
    font-size: 28px;
  }
}

.beer-inner__subtitle {
  text-align: center;
  font-family: 'FuturaPT-Light';
  font-size: 36px;
  line-height: 1.3;
  text-transform: uppercase;
  font-weight: 300;
  padding: 7px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.4);
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
}

@media screen and (max-width: 767px) {
  .beer-inner__subtitle {
    font-size: 28px;
  }
}

.beer-inner__text-content {
  max-width: 755px;
  margin: 0 auto;
  padding: 0 35px 45px;
  background-color: #fff;
}

@media screen and (max-width: 992px) {
  .beer-inner__text-content {
    max-width: 570px;
  }
}

@media screen and (max-width: 767px) {
  .beer-inner__text-content {
    max-width: none;
    margin: 0 15px;
    padding: 15px;
  }
}

.inner__notice-author {
  max-width: 155px;
  display: inline-block;
  vertical-align: top;
  margin-top: 75px;
  color: #fff;
}

@media screen and (max-width: 767px) {
  .inner__notice-author {
    display: none;
  }
}

.inner__notice-author-photo {
  max-width: 50px;
  max-height: 50px;
  display: inline-block;
  vertical-align: middle;
}

.inner__notice-author span {
  font-family: 'FuturaPT-Book';
  font-size: 14px;
  color: #000;
  letter-spacing: 1px;
}

.beer-inner__page .inner__text {
  margin-top: 30px;
  width: calc(100% - 170px);
}

@media screen and (max-width: 767px) {
  .beer-inner__page .inner__text {
    float: none;
    width: 100%;
    margin-top: 0;
  }
}

.inner__text-image {
  max-height: 380px;
}

@media screen and (max-width: 767px) {
  .inner__text-image {
    width: 100%;
    max-height: none;
    height: auto;
  }
}

.beer-inner__page .inner__text-share {
  max-width: 80%;
}

@media screen and (max-width: 992px) {
  .beer-inner__page .inner__text-share {
    max-width: 92%;
  }
  .beer-inner__page .inner__text-share svg {
    width: 20px;
  }
  .beer-inner__page .inner__text-facebook svg {
    width: 10px;
  }
}

@media screen and (max-width: 767px) {
  .beer-inner__page .inner__text-share {
    max-width: none;
  }
}

.beer-inner__page .bold-border {
  padding: 0 65px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.beer-inner__slider {
  background-color: #32a5b3;
}

.beer-inner__slider img {
  max-height: 400px;
}

.beer-inner__slider .swiper-slide {
  text-align: center;
}

.beer-inner__slider .swiper-slide a {
  display: inline-block;
}

/* .beer-inner__slider .swiper-button-prev,
.beer-inner__slider .swiper-button-next {
  background: none !important;
}

.beer-inner__slider .swiper-button-prev {
  text-align: left;
}

.beer-inner__slider .swiper-button-next {
  text-align: right;
} */

.beer-inner__slider .fa {
  color: #fff;
}