.notfound {
    background: url('../img/notfound-bg.jpg') no-repeat;
    background-size: cover;
}

.notfound .page__container {
    position: relative;
    z-index: 20;
    height: 100vh;
}

.notfound::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 10;
}

.notfound__title {
    padding-top: 160px;
    font-family: 'FuturaPT-ExtraBold';
    font-size: 48px;
    line-height: 1.13;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
}

@media screen and (max-width: 767px) {
    .notfound__title {
        padding-top: 60px;
        font-size: 28px;
        line-height: 2;
    }
}

.notfound__description {
    padding-top: 27px;
    font-family: 'FuturaPT-Book';
    font-size: 24px;
    line-height: 1.33;
    max-width: 650px;
    margin: 0 auto;
    text-align: center;
    color: #fff;
}

@media screen and (max-width: 767px) {
    .notfound__description {
        font-size: 14px;
        padding-top: 19px;
        max-width: 70%;
    }
}

.notfound__description a {
    text-decoration: underline;
    color: #fff;
}

.notfound__image {
    display: block;
    margin: 75px auto;
}

@media screen and (max-width: 992px) {
    .notfound__image {
        padding: 0 75px;
    }
}

@media screen and (max-width: 767px) {
    .notfound__image {
        padding: 0 25px;
    }
}

.notfound__image img {
    width: 100%;
}