.association__preview-text {
    font-family: 'FuturaPT-Book';
    font-size: 16px;
    line-height: 1.8;
    text-transform: uppercase;
    max-width: 655px;
    margin-bottom: 35px;
}

@media screen and (max-width: 992px) {
    .association__preview-text {
        font-size: 14px;
    }
}

@media screen and (max-width: 767px) {
    .association__preview-text {
        font-size: 12px;
    }
}

.association__items-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    margin-bottom: 15px;
}

@media screen and (max-width: 992px) {
    .association__items-wrapper {
        -ms-flex-pack: distribute;
        justify-content: space-around;
    }
}

.association__item {
    width: calc(20% - 10px);
    margin: 0px 10px 10px 0;
    padding: 45px;
    background-color: #fff;
    text-align: center;
}

.news__items-wrapper{
    width: 105%;
}

.news_rubric-item{
     width: 31% !important;
     padding: 45px 45px 0px 45px !important;
     margin: 0 10px 10px 0px !important;
}

@media screen and (max-width: 767px){
    .news_rubric-item{
        width: 45% !important;
    }
}

@media screen and (max-width: 550px){
    .news_rubric-item{
        width: 90% !important;
    }
}

.news_rubric-item__date{
    text-align: left;
}

.news_rubric-item__title{
    text-align: left;
}

.news_rubric-icon{
    width: 50px !important;
    height: 50px !important;
    right: 15px !important;
    bottom: 15px !important;
}

@media screen and (max-width: 1280px) {
    .association__item {
        padding: 35px 30px;
    }
}

@media screen and (max-width: 992px) {
    .association__item {
        width: calc(25% - 10px);
    }
}

@media screen and (max-width: 767px) {
    .association__item {
        width: calc(50% - 10px);
        padding: 15px;
    }
}

.association__item-image {
    max-height: 160px;
    height: auto;
}

@media screen and (max-width: 767px) {
    .association__item-image {
        max-height: unset;
    }
}

.association__item img {
    max-width: 100%;
    max-height: 100%;
}

.association__item-title {
    font-family: 'FuturaPT-ExtraBold';
    font-size: 16px;
    letter-spacing: 0.4px;
    text-transform: uppercase;
    word-break: break-word;
}

@media screen and (max-width: 767px) {
    .association__item-title {
        font-size: 14px;
    }
}

.association__item-link {
    font-family: 'FuturaPT-Light';
    font-size: 10px;
    letter-spacing: 0.3px;
    word-wrap: break-word;
}