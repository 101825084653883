.pagination {
    margin-right: auto;
    margin-left: auto;
    -ms-flex-item-align: end;
        align-self: flex-end;
}

@media screen and (max-width: 767px) {
    .pagination {
        width: unset;
        margin-right: auto;
        margin-left: auto;
        -ms-flex-item-align: center;
            align-self: center;
    }
}


.pagination__inner {
    max-width: max-content;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 0 auto 50px;
}

.pagination__arrow-left, .pagination__arrow-right {
    width: 30px;
    height: 30px;
}

.pagination__arrow-left {
    background: url('../img/icons/left-chevron.svg') center no-repeat #fff;
    background-size: 11px;
}

.pagination__arrow-right {
    background: url('../img/icons/right-chevron.svg') center no-repeat #fff;
    background-size: 11px;
}

.pagination__holder {
    background-color: #fff;
    font-family: 'FuturaPT-Book';
    font-size: 14px;
    letter-spacing: 1.5px;
    margin: 0 5px;
    padding: 0 20px;
}

.pagination__holder span {
    padding: 6px 2px;
    display: inline-block;
}

.pagination__holder .active {
    color: #32a5b3;
    position: relative;
    border-bottom: 1px solid #32a5b3;
}

.pagination__holder .active::after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 1px;
    color: #32a5b3;
}