:root {
    --news-item-width: 45%;
}

.read-also {
    padding-bottom: 30px;
}

.read-also__items {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    width: 100%;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -ms-flex-negative: 4;
        flex-shrink: 4;
}

.read-also__item {
    width: 24.5%;
    text-align: left;
    padding: 45px;
    background: #fff;
    margin-bottom: 10px;
}

@media screen and (max-width: 1240px) {
    .xl {
        padding: 30px;
    }

    .read-also__item {
        padding: 20px;
    }
}

@media screen and (max-width: 767px) {
    .read-also__item {
        padding: 10px;
        width: 100%;
        text-align: left;
    }
}

.xl {
    width: 32%;
}

@media screen and (max-width: 767px) {
    .xl {
        width: 49%;
    }
}

.read-also__item-image {
    width: 100%;
    height: auto;
    display: block;
}

@media screen and (max-width: 767px) {
    .read-also__item-image {
        max-width: 37%;
        height: auto;
        float: left;
        margin-right: 5px;
    }
}

@media screen and (max-width: 767px) {
    .xl .read-also__item-image {
        width: 100%;
        display: block;
        margin: 0 auto 5px;
        float: none;
        max-width: none;
    }
}

.read-also__item-magazine {
    font-family: 'FuturaPT-Bold';
    font-size: 12px;
    letter-spacing: 1px;
    padding-bottom: 5px;
    text-transform: uppercase;
}

@media screen and (max-width: 767px) {
    .read-also__item-magazine {
        text-align: center;
        padding-bottom: 10px;
    }
}

.read-also__item-title {
    font-family: 'FuturaPT-Demi';
    font-size: 24px;
    line-height: 1.3;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    padding-top: 15px;
}

@media screen and (max-width: 1240px) {

    .read-also__item .read-also__item-title {
        font-size: 20px;
    }
    .xl .read-also__item-title {
        font-size: 22px;
        text-align: left;
    }
}

@media screen and (max-width: 767px) {
    .read-also__item .read-also__item-title {
        font-size: 12px;
    }
    .xl .read-also__item-title {
        padding-top: 5px;
        font-size: 14px;
    }
}

.read-also__item-description {
    font-family: 'Playfair-Display';
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 1px;
}

@media screen and (max-width: 1240px) {
    .read-also__item-description {
        font-size: 13px;
        line-height: 20px;
    }
}

@media screen and (max-width: 767px) {
    .read-also__item-description {
        font-size: 9px;
        line-height: 13px;
        letter-spacing: 0.7px;
    }
}
.news {
    margin-bottom: 40px;
}

@media screen and (max-width: 767px) {
    .news, .news-border {
        display: none;
    }
}

.news-item__wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    padding: 20px 0;
}

@media screen and (max-width: 767px) {
    .news-item__wrapper {
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    }
    .news-item{
        width: var(--news-item-width) !important;
    }
}

.news-item {
    width: 25%;
    margin: 0 10px;
    position: relative;
}

@media screen and (max-width: 1240px) {
    .news-item {
        margin: 0 5px;
    }
}

.news__date {
    font-family: 'FuturaPT-Light';
    font-size: 16px;
    padding-bottom: 10px;
    letter-spacing: 2.9px;
    color: #b82c46;
    text-transform: uppercase;
}

@media screen and (max-width: 1240px) {
    .news__date {
        font-size: 13px;
        padding-bottom: 12px;
    }
}

@media screen and (max-width: 992px) {
    .news__date {
        font-size: 9px;
    }
}

.news-item__title {
    font-family: 'Playfair-Display';
    font-weight: bold;
    font-size: 20px;
    letter-spacing: 0.3px;
    margin-bottom: 50px;
    line-height: 1.2;
}

@media screen and (max-width: 992px) {
    .news-item__title {
        font-size: 16px;
    }
}

.news__icon {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 60px;
    height: 60px;
}

.footer {
    border: 1px solid #000;
    margin: 0 65px 30px;
}

@media screen and (max-width: 992px) {
    .footer {
        margin: 0 20px 30px;
    }
}

@media screen and (max-width: 767px) {
    .footer {
        margin: 0 10px 20px;
    }
}

.footer__title {
    padding: 25px 0 0;
    text-transform: uppercase;
    font-family: 'FuturaPT-Bold';
    font-size: 26px;
    text-align: center;
    width: 100%;
}

.footer__menu {
    padding: 0 70px 15px;
    text-align: center;
    border-bottom: 1px solid #000;
}

@media screen and (max-width: 1240px) {
    .footer__menu {
        padding: 0 0 15px;
    }
}

.footer__menu ul {
    padding: 0;
}

.footer__menu-list-item {
    display: inline-block;
    margin-right: 5px;
    font-weight: bold;
}

@media screen and (max-width: 992px) {
    .footer__menu-list-item {
        margin-right: 0;
    }
}

.footer__menu-list-item a {
    font-family: 'FuturaPT-Bold';
    font-size: 18px;
    color: #000;
    text-decoration: none;
}

@media screen and (max-width: 992px) {
    .footer__menu-list-item a {
        font-size: 14px;
    }
}

@media screen and (max-width: 767px) {
    .footer__menu-list-item a {
        font-size: 12px;
    }
}

.footer__menu li a:hover {
    text-decoration: underline;
}

.footer>.digest {
    margin-bottom: 0;
    border: none;
    border-bottom: 1px solid #000;
}

.footer__submenu {
    padding: 25px 0;
}

@media screen and (max-width: 767px) {
    .footer__submenu {
        padding: 12px 0;
    }
}

.footer__submenu ul {
    margin: 0;
}

.footer__submenu .footer__menu-list-item a {
    font-family: 'FuturaPT-Book';
}

.footer__social {
    padding: 45px 0 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    max-width: 380px;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    margin: 0 auto;
}

.social__link-inner {
    width: 100%;
    height: 100%;
    display: inline-block;
}

.social__link-inner svg {
    border-radius: 100%;
    -webkit-transition: .3s;
    transition: .3s;
}

.social__link-inner svg:hover {
    background: #fff;
}

@media screen and (max-width: 767px) {
    .footer__social {
        max-width: 270px;
        padding: 22px 0;
    }
    .social__link-inner svg {
        height: 43px;
        width: 43px;
    }
}

.disclaimer {
    text-align: center;
    font-size: 28px;
    font-family: 'FuturaPT-Book';
    padding: 22px 70px;
    opacity: .5;
    border-top: 1px solid #000;
}

@media screen and (max-width: 1240px) {
    .disclaimer {
        padding: 22px 0;
    }
}

@media screen and (max-width: 992px) {
    .disclaimer {
        font-size: 20px;
    }
}

@media screen and (max-width: 767px) {
    .disclaimer {
        font-size: 15px;
        padding: 16px 5%;
    }
}