@import url('https://cdnjs.cloudflare.com/ajax/libs/normalize/7.0.0/normalize.min.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css');

@font-face {
    font-family: "FuturaPT-Regular";
    src: url(../fonts/FuturaPT-Regular/FuturaPT-Regular.eot?) format("eot"), url(../fonts/FuturaPT-Regular/FuturaPT-Regular.woff) format("woff"), url(../fonts/FuturaPT-Regular/FuturaPT-Regular.ttf) format("truetype"), url(../fonts/FuturaPT-Regular/FuturaPT-Regular.svg);
}

@font-face {
    font-family: "FuturaPT-Bold";
    src: url(../fonts/FuturaPT-Bold/FuturaPT-Bold.eot?) format("eot"), url(../fonts/FuturaPT-Bold/FuturaPT-Bold.woff) format("woff"), url(../fonts/FuturaPT-Bold/FuturaPT-Bold.ttf) format("truetype"), url(../fonts/FuturaPT-Bold/FuturaPT-Bold.svg);
}

@font-face {
    font-family: "FuturaPT-Book";
    src: url(../fonts/FuturaPT-Book/FuturaPT-Book.eot?) format("eot"), url(../fonts/FuturaPT-Book/FuturaPT-Book.woff) format("woff"), url(../fonts/FuturaPT-Book/FuturaPT-Book.ttf) format("truetype"), url(../fonts/FuturaPT-Book/FuturaPT-Book.svg);
}

@font-face {
    font-family: "FuturaPT-Demi";
    src: url(../fonts/FuturaPT-Demi/FuturaPT-Demi.eot?) format("eot"), url(../fonts/FuturaPT-Demi/FuturaPT-Demi.woff) format("woff"), url(../fonts/FuturaPT-Demi/FuturaPT-Demi.ttf) format("truetype"), url(../fonts/FuturaPT-Demi/FuturaPT-Demi.svg);
}

@font-face {
    font-family: "FuturaPT-ExtraBold";
    src: url(../fonts/FuturaPT-ExtraBold/FuturaPT-ExtraBold.eot?) format("eot"), url(../fonts/FuturaPT-ExtraBold/FuturaPT-ExtraBold.woff) format("woff"), url(../fonts/FuturaPT-ExtraBold/FuturaPT-ExtraBold.ttf) format("truetype"), url(../fonts/FuturaPT-ExtraBold/FuturaPT-ExtraBold.svg);
}

@font-face {
    font-family: "FuturaPT-Light";
    src: url(../fonts/FuturaPT-Light/FuturaPT-Light.eot?) format("eot"), url(../fonts/FuturaPT-Light/FuturaPT-Light.woff) format("woff"), url(../fonts/FuturaPT-Light/FuturaPT-Light.ttf) format("truetype"), url(../fonts/FuturaPT-Light/FuturaPT-Light.svg);
}

@font-face {
    font-family: "Playfair-Display";
    src: url(../fonts/Playfair-Display/Playfair-Display.woff) format("woff"), url(../fonts/Playfair-Display/Playfair-Display.ttf) format("truetype"), url(../fonts/Playfair-Display/Playfair-Display.svg);
}

* {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    outline: none;
}

::-webkit-input-placeholder {
    color: #000;
    font-family: 'FuturaPT-Book';
    letter-spacing: 2px;
    text-transform: lowercase;
}

::-moz-placeholder {
    color: #000;
    font-family: 'FuturaPT-Book';
    letter-spacing: 2px;
    text-transform: lowercase;
}

/* Firefox 19+ */

:-moz-placeholder {
    color: #000;
    font-family: 'FuturaPT-Book';
    letter-spacing: 2px;
    text-transform: lowercase;
}

/* Firefox 18- */

:-ms-input-placeholder {
    color: #000;
    font-family: 'FuturaPT-Book';
    letter-spacing: 2px;
    text-transform: lowercase;
}

ul {
    padding: 0;
}

label {
    cursor: pointer;
    min-width: 100px;
    display: inline-block;
    font-size: 12px;
    font-family: 'FuturaPT-Bold';
    letter-spacing: -0.2px;
    color: #2e2e2e;
    padding-bottom: 9px;
}

input,
textarea {
    background: transparent;
    -webkit-box-shadow: none;
            box-shadow: none;
    border: 1px solid rgba(74, 74, 74, 0.22);
}

body {
    background-image: -webkit-gradient(linear, left top, left bottom, from(#e9f3f5), color-stop(21%, #f1eeec), color-stop(38%, #fcf5eb), color-stop(56%, #eff6e3), color-stop(76%, #eff6e3), to(#eeeef5));
    background-image: linear-gradient(to bottom, #e9f3f5, #f1eeec 21%, #fcf5eb 38%, #eff6e3 56%, #eff6e3 76%, #eeeef5);
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
    padding: 0;
}

p {
    font-family: 'FuturaPT-Book';
}

a {
    color: #000;
    text-decoration: none;
}

.clearfix:after {
    content: "";
    display: table;
    clear: both;
}

.dark {
    width: 100%;
    height: 100%;
    display: none;
    position: fixed;
    z-index: 50;
    background: rgba(0, 0, 0, 0.75);
}

.blured {
    -webkit-filter: blur(7px);
            filter: blur(7px);
    -webkit-transition: .1s;
    transition: .1s;
}

.show {
    -webkit-transform: translateX(0) !important;
            transform: translateX(0) !important;
}

.showsub {
    -webkit-transform: translateX(100%) !important;
            transform: translateX(100%) !important;
}


.page__container {
    padding: 35px 65px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    max-width: 1440px;
    margin: 0 auto;
    margin-left: 10%;
    margin-right: 10%;
}

@media screen and (max-width: 2400px){
    .page__container{
        margin-left: auto;
        margin-right: auto;
    }

}

@media screen and (max-width: 1240px) {
    .page__container {
        padding: 35px;
    }
}

@media screen and (max-width: 992px) {
    .page__container {
        padding: 25px 20px;
    }
}

@media screen and (max-width: 767px) {
    .page__container {
        padding: 25px 10px;
        margin-left: unset;
        margin-right: unset;
    }
}

@media screen and (max-width: 360px) {
    .page__container {
        padding: 15px 5px;
    }
}

.page__title {
    padding-left: 64px;
    font-family: 'FuturaPT-ExtraBold';
    font-size: 48px;
    text-decoration: underline;
    letter-spacing: 1px;
    color: #fff;
    text-transform: uppercase;
    position: absolute;
    bottom: 30px;
}

@media screen and (max-width: 767px) {
    .page__title {
        padding-left: 12px;
        font-size: 38px;
        bottom: 24px;
        max-width: 100%;
        word-break: break-all;
    }
}

.page__subtitle {
    font-family: 'FuturaPT-Demi';
    font-size: 12px;
    letter-spacing: 1px;
    text-align: center;
    text-transform: uppercase;
}

.display-block {
    display: block !important;
}

.block-align-right {
    width: calc(100% - 220px);
    -ms-flex-item-align: end !important;
        align-self: flex-end !important;
}

.arrow-down {
    background: url('../img/icons/arrow.svg') no-repeat;
    background-size: 15px;
    background-position-y: center;
    background-position-x: 95%;
}

.rotate {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    -webkit-transition: .5s;
    transition: .5s;
}

@media screen and (max-width: 992px) {
    .block-align-right {
        width: calc(100% - 205px) !important;
    }
}

@media screen and (max-width: 767px) {
    .block-align-right {
        width: 100% !important;
    }
}

.button__regular {
    background: transparent;
    text-transform: uppercase;
    font-family: 'FuturaPT-Book';
    letter-spacing: 2px;
    font-size: 16px;
    text-align: center;
    padding: 20px;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    border: none;
    text-decoration: none;
    -webkit-transition: .3s;
    transition: .3s;
}

@media screen and (max-width: 992px) {
    .button__regular {
        font-size: 14px;
    }
}

.button__regular:hover {
    -webkit-transition: .3s;
    transition: .3s;
    background: black;
    color: #fff;
}

.button__loadmore {
    display: block;
    max-width: 210px;
    margin: 0 auto 45px;
    position: relative;
    background: transparent;
    border: 2px solid black;
    -webkit-box-shadow: -12px 8px 0px 0px black;
            box-shadow: -12px 8px 0px 0px black;
}

.button__loadmore::before {
    content: '';
    display: block;
    position: absolute;
    z-index: -1;
    top: -2px;
    left: -14px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 8px 13px;
    border-color: transparent transparent black transparent
}

.button__loadmore::after {
    content: '';
    display: block;
    position: absolute;
    z-index: -1;
    bottom: -10px;
    right: -2px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8px 12px 0 0;
    border-color: black transparent transparent transparent;
}

.bold-border {
    height: 10px;
    width: 100%;
    background-color: #1d1d1b;
}

@media screen and (max-width: 767px) {
    .bold-border {
        height: 6px;
    }
}

.xl {
    width: 32%;
}

.inner-page-title {
    font-family: 'FuturaPT-ExtraBold';
    font-size: 28px;
    letter-spacing: 2.9px;
    text-align: center;
    padding: 22px 0;
    width: 100%;
    text-transform: uppercase;
}

@media screen and (max-width: 1240px) {
    .inner-page-title {
        padding: 22px 0 10px;
    }
}

@media screen and (max-width: 992px) {
    .inner-page-title {
        font-size: 26px;
    }
}

@media screen and (max-width: 767px) {
    .inner-page-title {
        font-size: 15px;
    }
}

.holder__block {
    position: relative;
}

/* .icon-beer {
    background: url('../img/beer/icon-beer.png') center no-repeat;
    background-size: contain;
}

.icon-fire {
    background: url('../img/beer/icon-fire.png') center no-repeat;
    background-size: contain;
}

.icon-smile,
.icon-tag {
    background: url('../img/beer/icon-smile.png') center no-repeat;
    background-size: contain;
} */

/* BEER INDICATORS */

.beer__item-indicators {
    width: 100%;
    padding: 5px 10px;
    margin-top: 5px;
}

.beer__item-indicators-value {
    display: inline-block;
    vertical-align: top;
}

.beer__item-indicators-value:nth-child(2) {
    padding: 0 10px;
}

.beer__item-value-bold,
.beer__item-value-normal {
    font-family: 'FuturaPT-Demi';
    font-size: 9px;
    letter-spacing: 0.8px;
}

.beer__item-value-normal {
    font-family: 'FuturaPT-Light';
}

/* Inner page text */

.inner__text {
    float: right;
    font-family: 'Playfair-Display';
    font-size: 16px;
    line-height: 1.8;
    width: calc(100% - 160px);
    position: relative;
}

.inner__text p, .rich-text p {
    font-family: 'Playfair-Display';
}

.inner__text iframe {
    width: 100%;
}

@media screen and (max-width: 992px) {
    .inner__text {
        font-size: 14px;
    }
}

@media screen and (max-width: 767px) {
    .inner__text {
        font-size: 14px;
        width: 100%;
        max-width: none;
        margin-top: 0;
    }
}

.inner__text .role {
    font-weight: bold;
}

.inner__text-title {
    font-family: 'FuturaPT-Demi';
    font-size: 18px;
    line-height: 1;
    font-weight: bold;
    position: relative;
    margin-bottom: 25px;
    float: right;
    width: 100%;
}

@media screen and (max-width: 767px) {
    .inner__text-title {
        font-size: 16px;
        left: 0;
        margin: 30px 0;
        width: 100%;
    }
}

/* Inner text share */

.inner__text-share {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}

.inner__text-share-item {
    margin: 0 auto;
    border: 1px solid;
    width: 33%;
    text-align: center;
    max-height: 50px;
    -webkit-transition: .5s;
    transition: .5s;
    cursor: pointer;
}

.inner__text-facebook svg {
    width: 12px;
}

.inner__text-share-item svg {
    width: 25px;
    fill: #000;
}

.inner__text-share-item a {
    width: 100%;
    height: 100%;
    display: inline-block;
}

.inner__text-facebook {
    padding: 7px 0;
}

.inner__text-vk {
    padding: 15px 0;
}

.inner__text-instagram {
    padding: 7px 0;
}

.inner__text-telegram {
    padding: 10px 0;
}

/* Slider styles for nav buttons */

.swiper-button-prev,
.swiper-button-next {
    background: none !important;
}

.swiper-button-prev {
    text-align: left;
}

.swiper-button-next {
    text-align: right;
}

.swiper{
    padding-top: 15px;

}

/* fix back-end issue with paragraphs */

.rich-text {
    padding: 0 !important;
    margin: 0 !important;
}

.rich-text img {
    max-width: 100%;
    display: block;
    margin: 5px auto;
}

.rich-text a,
.inner__content-preview a{
    text-decoration: underline;
}

.responsive-object {
    position: relative;
}

.responsive-object iframe,
.responsive-object object,
.responsive-object embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
