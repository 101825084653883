.responsibility__page {
    position: relative;
}

.responsibility__text {
    font-size: 16px;
    line-height: 1.9;
    padding-left: 65px;
    max-width: 62%;
    margin-bottom: 30px;
    padding: 30px 0 0px 65px;
}


.responsibility__text .map{
    width: 640px;
    height: 450px
}


.responsibility__text-column{
    display: inline-block;
    vertical-align: top;
}

.responsibility__text .rich-text {
    width: 100%;
}

.responsibility__text .rich-text p{
    font-family: FuturaPT-Book !important;
}

.responsibility__text .rich-text p b{
    font-family: FuturaPT-Bold !important;
}

.responsibility__text .rich-text a{
    font-family: FuturaPT-Book !important;
    color: #00788c;
}

.responsibility__text .rich-text h1, h2, h3, h4, h5, h6 {
    font-family: FuturaPT-ExtraBold !important;
    letter-spacing: 2.9px;
}

.responsibility .rich-text p {
    margin-bottom: 30px;
}

@media screen and (max-width: 1240px) {
    .responsibility__text {
        max-width: 85%;
    }
    .responsibility__text .rich-text {
        max-width: 85%;
    }

}


@media screen and (max-width: 1440px) {
    .responsibility__text .map{
        width: 471px;
        height: 450px;
    }
}
@media screen and (max-width: 992px) {
    .responsibility__text {
        max-width: none;
        padding: 25px 27px;
        margin-bottom: 25px;
    }
    .responsibility__text .rich-text {
        max-width: none;
        padding: 0 27px;
        margin-bottom: 25px;
    }

    .responsibility__text .map{
        width: 100%;
        height: 348px;
    }

    .responsibility__text-column{
        display: block;
    }

}

.responsibility__text:first-of-type {
    padding-top: 30px;
}

.responsibility__text .rich-text:first-of-type {
    padding-top: 30px;
}