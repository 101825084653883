.event__title {
    font-family: 'FuturaPT-ExtraBold';
    font-size: 48px;
    line-height: 1;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 15px;
}

@media screen and (max-width: 992px) {
    .event__title {
        font-size: 36px;
    }
}

@media screen and (max-width: 767px) {
    .event__title {
        font-size: 28px;
        letter-spacing: 0.8px;
    }
}

.event__poster {
    margin: 0 auto 10px;
}

.event__poster img {
    width: 100%;
    height: 100%;
}

.event__details {
    background-color: #fff;
    margin: -30px auto 0;
    padding: 30px 0;
    max-width: 440px;
    text-align: center;
    position: relative;
}

@media screen and (max-width: 767px) {
    .event__details {
        margin-top: -5px;
        padding: 22px 0;
    }
}

.event__details-title {
    font-family: 'FuturaPT-Bold';
    font-size: 16px;
    text-transform: uppercase;
    margin-bottom: 5px;
}

@media screen and (max-width: 767px) {
    .event__details-title {
        font-size: 14px;
        margin-bottom: 0;
    }
}

.event__details-date, .event__details-address {
    font-family: 'FuturaPT-Light';
    color: rgba(0, 0, 0, 0.5);
    margin-bottom: 5px;
}


@media screen and (max-width: 767px) {
    .event__details-date, .event__details-address {
        font-size: 14px;
        margin-bottom: 0;
    }
}

.event__description {
    max-width: 650px;
    margin: 0 auto 40px;
    background-color: #fff;
    padding: 35px 110px;
}


.event__description .inner__text{
    width: unset;
    display: contents;
}

.event__description .inner__text img{
    width: 100%;
}


@media screen and (max-width: 1280px) {
    .event__description {
        padding: 35px 80px;
        max-width: 580px;
    }
}

@media screen and (max-width: 767px) {
    .event__description {
        padding: 19px 17px;
    }
}

.event__description p {
    font-family: 'Playfair-Display';
    font-size: 16px;
    line-height: 1.7;
    margin-bottom: 15px;
}

@media screen and (max-width: 1280px) {
    .event__description p {
        font-size: 14px;
        line-height: 1.8;
    }
}

.event__guests-title {
    font-family: 'FuturaPT-Demi';
    font-size: 18px;
    line-height: 1.6;
    margin-bottom: 15px;
}

@media screen and (max-width: 767px) {
    .event__guests-title {
        font-size: 14px;
    }
}

.event__guests {
    margin-bottom: 35px;
}

.event__guests-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}

.event__guests-item {
    width: 140px;
    height: 140px;
    margin-bottom: 5px;
}

@media screen and (max-width: 320px) {
    .event__guests-item {
        width: 120px;
        height: 120px;
    }
}

.event__guests-item img {
    width: 100%;
    height: 100%;
}

.another-events-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    margin-top: 10px;
    padding-bottom: 30px;
}

@media screen and (max-width: 767px) {
    .another-events-wrapper {
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    }
}

.another-events-item {
    width: 32%;
    background-color: #fff;
    padding-bottom: 30px;
}

@media screen and (max-width: 767px) {
    .another-events-item {
        width: 49%;
        margin-bottom: 10px;
    }
}

.another-events-item img {
    width: 100%;
    height: 80%;
}

@media screen and (max-width: 992px) {
    .another-events-item img {
        max-height: 155px;
    }
}

.another-events-date {
    font-family: 'FuturaPT-Light';
    font-size: 18px;
    line-height: 1.8;
    color: #b82c46;
    padding: 0 45px;
    text-transform: uppercase;
}

@media screen and (max-width: 1280px) {
    .another-events-date {
        font-size: 14px;
        padding: 0 35px;
    }
}

@media screen and (max-width: 992px) {
    .another-events-date {
        font-size: 12px;
        padding: 0 22px;
    }
}

@media screen and (max-width: 767px) {
    .another-events-date {
        font-size: 9px;
        padding: 0 14px;
    }
}

.another-events-title {
    font-family: 'FuturaPT-Demi';
    font-size: 24px;
    line-height: 1.4;
    padding: 0 25px 0 45px;
    text-transform: uppercase;
}


@media screen and (max-width: 1280px) {
    .another-events-title {
        font-size: 22px;
        padding: 0 35px;
    }
}


@media screen and (max-width: 992px) {
    .another-events-title {
        font-size: 20px;
        padding: 0 22px;
    }
}

@media screen and (max-width: 767px) {
    .another-events-title {
        font-size: 14px;
        padding: 0 14px;
    }
}