:root {
    --test-inputs-height: 61px;
    --test-inputs-width: 630px;
    --step-bottom: 30px;
    --inside-padding: 20px 0 20px 15px;
    --input-border: solid 2px #7d7878;
}

@media screen and (max-width: 992px) {
    .test {
        width: 100%
    }

    .test .digest__form-background {
        max-width: unset !important;
    }

    :root {
        --test-inputs-height: 100%;
        --test-inputs-width: 100%;
    }
}

.test {
    display: table;
    text-align: center;
    margin: auto;
}

.test form .title,
.test .title p {
    font-family: 'FuturaPT-ExtraBold' !important;
    font-size: 25px;
    font-weight: 800;
}

.test .title {
    margin-bottom: var(--step-bottom);
}

.test .text {
    width: var(--test-inputs-width);
}


@media screen and (max-width: 767px) {
    .test .text .rich-text p {
        font-size: 16px;
    }
}

.test form .title {
    margin-bottom: var(--step-bottom);
}

.test input {
    padding: var(--inside-padding);
    width: var(--test-inputs-width);
    min-height: var(--test-inputs-height);
    border: var(--input-border);
}

.test input::placeholder {
    color: #7d7878;
}

.test .fieldWrapper {
    padding-bottom: var(--step-bottom);
}

.test button {
    width: var(--test-inputs-width);
    height: var(--test-inputs-height);
    font-family: 'FuturaPT-Demi';
    font-size: 20px;
    text-align: center;
    text-transform: uppercase;
    -webkit-transition: .3s;
    transition: .3s;
    background: transparent;
}

.test .digest__form-background {
    width: var(--test-inputs-width);
    height: var(--test-inputs-height);
}

.test .digest__form-background::before {
    top: -1px;
    z-index: 0;
}

.test .digest__form-background::after {
    bottom: -6px;
    z-index: 0;
}

.test input[type="radio"]:checked + label {
    border-color: black;
    color: black;
}

.test .digest__form-background:hover {
    -webkit-transition: .3s;
    transition: .3s;
    background: black;
    color: #fff;
}

.test .digest__submit::after,
.test .digest__submit::before {
    all: unset;
}

.test .question label {
    margin-bottom: var(--step-bottom);
    padding: var(--inside-padding);
    width: var(--test-inputs-width);
    border: var(--input-border);
    color: #7d7878;
    text-align: left;
    font-size: 19px;
}

.test input[type="radio"] {
    position: absolute;
    opacity: 0;
}

.test .fieldWrapper input[type="hidden"] {
    margin-top: var(--step-bottom);
}

.test .count {
    padding-bottom: 20px;
    font-family: 'FuturaPT-Demi';
    font-size: 12px;
    color: #7d7878;
}

.test #test-counter {
    font-family: 'FuturaPT-Demi';
    font-size: 12px;
    color: #7d7878;
}

.test .clocks {
    margin-bottom: 20px;
}

.test .clocks img {
    left: unset;
    position: absolute;
    transform: translate3d(-30px, -35px, 0px);
    width: 60px;
}

.test .text .rich-text p{
    font-family: 'FuturaPT-Book' !important;
    font-size: 16px;
    text-align: left;
    margin-bottom: 20px;
}

.policy {
    padding: 15px 20% 15px;
}

@media screen and (max-width: 992px) {
    .policy {
        padding: 15px 15px;
    }
}

/* Customize the label (the container) */
.policy .label {
    display: block;
    position: relative;
    padding-left: 35px;
    padding-bottom: 0px;
    margin-bottom: 0px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.policy .label input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 1px;
    width: 1px;
    left: 10px;
    top: -45px;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 19px;
    width: 19px;
    background-color: white;
    border: 3px solid black;
}

.policy .label .checkmark:hover {
    background-color: #ccc;
}

.policy .label input:checked ~ .checkmark {
    background-color: black;
}

.policy a{
    font-family: FuturaPT-Demi;
    font-size: 20px;
}

.policy-inner{
    display:none;
    width: 70%;
    height: 80vh;
    background-color: #fff;
    position: fixed;
    left: 15%;
    top: 10%;
    -webkit-transition: .5s;
    transition: .5s;
    z-index: 100;
    overflow-y: auto;
}

.policy-inner__close {
    padding-bottom: 80px;
    width: 25px;
    height: 25px;
    margin: 50px auto 0;
    background: url('../img/icons/cross.svg') no-repeat;
    cursor: pointer;
    z-index: 101;
}

.policy-inner .rich-text {
    text-align: left;
    padding-right: 20px !important;
}


@media screen and (max-width: 1024px) {
    .policy-inner{
        left: 17%;
    }
}

@media screen and (max-width: 425px) {
    .policy-inner{
        left: 0%;
        top: 0%;
        width: 100%;
        height: 100%;
    }
}

#show-policy{
    cursor: pointer;
}

